.formula-title {
  padding: 0px;
  font-size: 2rem;
  letter-spacing: 0px !important;
  display: block;
  /* color: #666; */
  margin: 1rem 0px 1rem 0px;
  font-weight: 400;
}

.required {
  color: #e02222;
  font-size: 1rem;
  /* padding-left: 0.2rem; */
}

.hide {
  display: none;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

/* Gerneral Section */
.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  width: 100%;
}

.Add_PATIENT_IFRAME {
  width: 100%;
  height: 75vh;
}

.Add_PATIENT_IFRAME header,
.Add_PATIENT_IFRAME .main-content,
.Add_PATIENT_IFRAME .page-titles,
.Add_PATIENT_IFRAME .breadcrumbDiv,
.menu-sidebar {
  padding-top: 0px !important;
}

.Add_PATIENT_IFRAME .menu-sidebar {
  display: none !important;
}

.Add_PATIENT_IFRAME .page-container {
  padding-left: 0px !important;
}

.rs-dropdown-menu {
  min-width: 100px !important;
  max-width: 400px !important;
  max-height: 250px;
  overflow-y: auto;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-input-number > .rs-input,
.rs-input {
  color: var(--bs-body-color);
}

/* Option Section */
.rs-input {
  text-align: left !important;
}

.rs-input::placeholder {
  color: "#8e8e93";
}

/* .rs-anim-fade.rs-popover.rs-popover-full {
width: 50rem !important;
-webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
  0 0 10px rgba(0, 0, 0, 0.06);
filter: inherit;
border-radius: 6px !important;
left: 17.4% !important;
margin-top: 10px;
} */
.rs-popover-arrow {
  display: none !important;
}

/* Ingredient Section */
.rs-table-cell-header .rs-table-cell-content {
  font-weight: 600;
}

.rs-picker-toggle-label::after {
  margin-left: 0px !important;
}

/* iframe hide section */
iframe .page-titles,
iframe .breadcrumbDiv,
iframe .header-desktop,
iframe .menu-sidebar {
  display: none !important;
}

/* EXTRA */

.rs-table-cell-header .rs-table-cell-content {
  text-align: center;
  /* text-transform: capitalize; */
  padding: 0.6rem 1rem;
  opacity: 1;
  flex-grow: 1;
  font-size: 16px;
  background-color: #fff;
}

.ingredientTable td {
  text-align: center;
}

.ingredientTable td span {
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
}

/* .rs-table-cell {
height: 60px !important;
} */

.rs-picker-toggle-label {
  color: red !important;
}

.rs-table-cell-content {
  text-align: center;
}

.rs-input-number-btn-group-vertical {
  display: none !important;
}

.rs-input-number .rs-input {
  border-radius: 6px !important;
  text-align: center;
}

.rs-table-cell-content {
  overflow: initial !important;
}

.drag_formula_dosage_column_action {
  border: 1px solid gray;
  padding: 6px;
  border-radius: 6px;
  background-color: #fff;
  margin-right: 3px;
}

.view_formula_dosage_column_action {
  border: 1px solid gray;
  padding: 6px;
  border-radius: 6px;
  background-color: #fff;
  margin-right: 3px;
}

.delete_formula_dosage_column_action {
  border: 1px solid red;
  padding: 6px;
  border-radius: 6px;
  background-color: #fff;
}

.rs-picker-select-menu-item,
.rs-picker-select-menu-items {
  max-width: 60rem !important;
  /* width: 60rem !important; */
}

.form-control {
  height: 2.1625rem !important;
}

.FormulaBuilderServicesSection .rs-panel-header {
  padding: 14px !important;
}

.FormulaBuilderServicesSection .rs-panel-body {
  padding: 14px !important;
  padding-top: unset !important;
}

#IngredientSearchDrawer .rs-drawer-header-close {
  display: none;
}

/* .rs-picker-menu.rs-picker-check-menu, */
/* .rs-picker-menu.rs-picker-select-menu { */
.rs-ingredient-name-picker-select-menu {
  width: 50rem !important;
}

.rs-check-item .rs-checkbox-checker > label > span {
  /* color: aqua; */
  /* float: right; */
}

.rs-picker-none {
  text-align: center;
}

.FormulaIngredientSearch table th {
  text-transform: capitalize;
  text-align: center;
}

.FormulaIngredientSearch table td {
  text-align: center;
}

.ingredientAddToFormula {
  border: 1px solid rgb(136, 201, 235);
  /* padding: 6px; */
  border-radius: 6px;
  background-color: #fff;
}

.FormulaIngredientSearch .rs-table-cell-header .rs-table-cell-content {
  text-transform: capitalize;
}

.outofstock {
  float: right;
  font-style: italic;
  font-weight: 500;
  margin-top: -5px !important;
  margin-right: 2px !important;
  position: relative;
}
/* .rs-table-cell-group .rs-table-cell {
height: 65px !important;
} */

/* .rs-table-cell-group .rs-table-cell-content {
padding: 11px 10px;
border: 0 !important;
} */
/* div#table-wrapper .rs-table-row {
border: 1px solid var(--rs-table-border-color);
} */

.outOfStockRow > * {
  /* background-color: #e02222; */
}

.rs-table-cell {
  /* background-color: unset !important; */
}

.rs-picker-toggle-wrapper {
  max-width: unset !important;
  display: block !important;
  /* border: 1px solid black */
}

/* .rs-picker-check-menu-items {
width: 20rem !important;
} */
.rs-modal-full .rs-modal-content .rs-modal-body {
  overflow: unset !important;
}

.rs-FORMULA_DOSAGE_COLUMN_HERB_ADD-select-menu,
.rs-FORMULA_DOSAGE_COLUMN_MEASUREMENT-select-menu {
  /* width: 200px!important; */
}

.rs-picker-toggle {
  background-color: #ffffff;
}
.FormulaBuilderIngredientsSection .rs-picker-toggle {
  background-color: #ffffff !important;
}
.rs-stack {
  width: 100% !important;
}

.rs-ingredient-name-picker-none {
  text-align: center;
}

.rs-checkbox label {
  width: max-content !important;
  margin-right: 0.9rem;
}
.rs-drawer-content .rs-table-cell-content,
.rs-drawer-content .rs-table-cell-header .rs-table-cell-content {
  text-align: left !important;
  /* text-align: center !important; */
}

/* Limit the overflow and ensure text does not overlap */
.rs-stack-item {
  max-width: 100%;
  overflow: hidden;
}
.rs-picker-toggle-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  display: inline-flex;
}
/* .rs-table-cell[aria-colindex="5"] .rs-table-cell-content {
max-width: 190px !important; 
} */
.rs-picker-toggle.rs-btn {
  border: 1px solid black;
}
.rs-table-cell-content {
  padding: 9px 10px;
}

.FormulaBuilderIngredientsSection .rs-table-row {
  border: none !important;
  text-align: center !important;
}
.FormulaBuilderIngredientsSection .rs-table-row .rs-table-cell {
  height: auto !important;
  border: none !important;
}
.FormulaBuilderIngredientsSection .rs-table-cell-content {
  border: 1px solid var(--rs-table-border-color);
}

#iframe .main-content {
  font-family: "Courier New", Courier, monospace;
}

.rs-formula-option-baseIngredient-picker-select-menu
  .rs-picker-select-menu-item
  > div {
  padding: 6px 12px;
  border-bottom: 1px solid #7c7c7c5c;
}
.rs-formula-option-baseIngredient-picker-select-menu
  .rs-picker-select-menu-item {
  padding: 0;
}
.rs-formula-option-baseIngredient-picker-select-menu {
  padding: 0;
}

.rs-formula-option-baseIngredient-picker .rs-picker-toggle-value {
  display: block;
  overflow: visible;
}

.rs-formula-option-baseIngredient-picker .rs-picker-toggle.rs-btn {
  background: transparent !important;
  border: none;
}

/* .rs-formula-markup-select .rs-picker-toggle.rs-btn{
background: transparent !important;
border: none;
} */
.rs-popover .rs-dropdown-item {
  padding: 0;
}
.rs-popover .rs-dropdown-item div,
.rs-dosage_measurement_type-select-menu .rs-picker-select-menu-item div {
  padding: 8px 12px;
}
.custom-input-number,
.custom-input-number .rs-input {
  background: transparent !important;
}

.rs-formula-option-Capsules-picker-select-menu
  .rs-picker-select-menu-item
  > div {
  padding: 6px 12px;
  border-bottom: 1px solid #7c7c7c5c;
}
.rs-formula-option-Capsules-picker-select-menu .rs-picker-select-menu-item {
  padding: 0;
}
.rs-formula-option-Capsules-picker-select-menu {
  padding: 0;
}

.rs-formula-option-Capsules-picker .rs-picker-toggle-value {
  display: block;
  overflow: visible;
}

.rs-formula-option-Capsules-picker .rs-picker-toggle.rs-btn {
  background: transparent !important;
  border: none;
}

.FormulaIngredientSearch .rs-stack {
  width: 121% !important;
}

.rs-notification-content {
  max-width: 500px;
  padding: 20px;
}

.ingredientTabCheckbox .rs-picker-toggle.rs-btn.rs-btn-default {
  background: transparent !important;
  border: none;
}

.pull-left .rs-picker-toggle-value {
  text-align: center;
  display: block;
}
.dynamic-input .rs-input {
  background: transparent !important;
}
.rs-input-group.rs-input-group-inside {
  background: transparent !important;
}
.rs-picker-default .rs-picker-toggle {
  background: transparent !important;
  /* border: 1px solid black; */
}

.RefillSettingsMain .row {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1rem !important;
}

.option .row.mb-3 {
  margin-inline: 0 !important;
}
.formula-builder-main {
  padding-left: 13px;
  padding-right: 13px;
}
.formula-builder-main form {
  width: 100%;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 20px;
}
.formula-builder-main form h5.formula-title {
  margin-top: 0;
}
.checkBox {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
}
.dosage-input span {
  margin-bottom: 3px;
  display: block;
  font-size: 12.25px;
}
.dosage-input .rs-picker-default .rs-picker-toggle.rs-btn {
  border: 1px solid black;
}
body .col-form-label {
  padding-inline: 0;
}
.FormulaBuilderSaveButton button:first-child {
  margin-left: 0 !important;
}
.rs-picker-input-group.rs-input-group.rs-input-group-inside {
  border: none;
}
.rs-formula-markup-select-menu .rs-picker-select-menu-item {
  padding: 0;
}
.rs-formula-markup-select-menu .rs-picker-select-menu-item div {
  padding: 6px 12px;
  border-bottom: 1px solid #9d9d9d5c;
}
.rs-formula-markup-select-menu {
  padding: 0;
}
.rs-dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.FormulaBuilderMarkupSection .rs-picker-toggle.rs-btn {
  border: none;
  background: transparent;
}

.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.custom-checkbox:checked {
  background-color: black;
}

.custom-checkbox:checked::after {
  content: "✔";
  color: white;
  font-size: 14px; /* Adjust size of the checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rs-checkbox-control {
  border-radius: 4px;
}

.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:before {
  background-color: black !important;
  border: 1px solid white;
  font-size: 12px;
}

label:hover .rs-checkbox-control .rs-checkbox-inner:before {
  border: 1px solid rgb(14, 12, 12);
}

.FormulaBuilderDosagesSection .dosage-input .rs-input-group {
  border: 1px solid black;
}

.custom-input-number {
  position: relative;
  width: calc(100% - 40px);
  z-index: 1;
}
.custom-input-number + button.rs-btn,
.dosage-input-number + button.rs-btn {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

span.rs-dropdown-item-content.rs-text,
.rs-dosage_measurement_type-select-menu .rs-picker-select-menu-item {
  border-bottom: 1px solid #9d9d9d5c;
  padding: 0;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #050505;
}
.FormulaBuilderIngredientsSection .rs-input-number.rs-input-group {
  border: 1px solid black;
}
.FormulaBuilderPriceSection .TableMainadd td:first-child {
  width: 50% !important;
}

.FormulaBuilderPriceSection .TableMainadd td:last-child {
  width: 50% !important;
  text-align: center;
}
s.rs-text {
  background: transparent !important;
}
.rs-modal-header-close {
  display: none !important;
}
span#rs-\:r1g\:-describe {
  color: #343434;
}
.rs-picker-popup.rs-formula-option-baseIngredient-picker-select-menu {
  padding: 0;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  color: #343434 !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #343434 !important;
}
span#rs-\:rg\:-describe {
  color: #2f2e2e !important;
}

.FormulaBuilderDosagesSection .rs-picker-toggle-value {
  color: #343434 !important;
}

.FormulaBuilderOptionsSection .rs-picker-toggle-value {
  color: #343434 !important;
}
.FormulaBuilderOptionsSection
  .custom-input-number.rs-input-number.rs-input-group {
  width: calc(100% - 40px);
}
.row.dosage-input .rs-input-group input {
  width: calc(100% - 30px);
  z-index: 1;
  flex: unset;
}

.rs-popover .rs-dropdown-menu,
div.rs-picker-popup {
  padding: 0;
  max-width: 350px;
}
.ingredients-checkbox .row span.text-left {
  width: auto;
  padding: 0;
  flex: inherit;
}
.ingredients-checkbox .row {
  margin: 0;
  gap: 10px;
}
.ingredients-checkbox .rs-checkbox-checker {
  line-height: inherit;
  padding-left: 32px;
}
.col-sm-8:has(.ingredients-checkbox) {
  display: block !important;
}
.rs-checkbox-control .rs-checkbox-inner:before {
  border-color: #000000 !important;
}

.FormulaBuilderIngredientsSection .rs-table-row {
  position: unset;
  transform: unset !important;
}
.FormulaBuilderIngredientsSection .rs-table-body-row-wrapper {
  top: 0 !important;
}
.FormulaBuilderIngredientsSection .rs-table-cell-group {
  width: 100%;
  display: flex;
}
.FormulaBuilderIngredientsSection .rs-table-cell {
  position: unset;
}
.FormulaBuilderIngredientsSection
  .rs-table-row
  .rs-table-cell
  .rs-table-cell-content {
  width: 100% !important;
}
.FormulaBuilderIngredientsSection .rs-table-row .rs-table-cell-header,
.FormulaBuilderIngredientsSection .rs-table-row .rs-table-cell {
  flex: 1;
}
.rs-check-item .rs-checkbox-checker label {
  width: auto !important;
  word-wrap: break-word;
}
.rs-formula-option-Capsules-picker .rs-picker-toggle.rs-btn {
  padding-right: 35px;
}
.rs-formula-option-Capsules-picker-select-menu .rs-picker-select-menu-item {
  word-wrap: break-word;
}

.row.dosage-input {
  row-gap: 24px;
}
.row.dosage-input .col {
  flex: inherit;
  width: auto;
  word-break: break-word;
}

.row.dosage-input .form-text {
  margin-top: 0;
}
.FormulaBuilderDosagesSection .col-10 {
  width: 81.66%;
}
button.rs-modal-header-close.ingredient_view_close {
  display: inline-block !important;
}
.FormulaBuilderDosagesSection .dosage-input .rs-input-group input {
  background: transparent;
}
.FormulaBuilderIngredientsSection
  .rs-table-row
  .rs-table-cell
  .rs-table-cell-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FormulaBuilderIngredientsSection .rs-table-cell .rs-table-cell-content > div {
  width: 100%;
}
.row.dosage-input button.rs-btn svg.rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.row.dosage-input button.rs-btn {
  background-image: url(FormulaBuilderDosagesSection/Background.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 10px;
  position: absolute;
  right: 0 !important;
  height: 100%;
  top: 0;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dosage-input .rs-input-group {
  overflow: hidden;
}
.dosage-input .rs-picker-toggle span {
  margin: 0;
}
