.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  width: 100%;
}

.FormulaBuilderGeneralSection iframe {
  width: 100%;
  height: 75vh;
}

iframe header,
iframe .main-content,
iframe .page-titles,
iframe .breadcrumbDiv,
iframe .menu-sidebar {
  display: none !important;
}

iframe .main-content {
  padding-top: 0px !important;
}

iframe .page-container {
  padding-left: 0px !important;
}
/* Apply base styles for the label and the input container */

.FormulaBuilderGeneralSection
  .col-6
  .rs-formula-name-picker-default
  .rs-picker-toggle.rs-btn {
  background: transparent !important;
  overflow: hidden;
}
.FormulaBuilderGeneralSection
  .col-6
  .rs-formula-name-picker-default
  .rs-stack-item {
  overflow: visible !important;
}
.FormulaBuilderGeneralSection .col-6 .rs-formula-name-picker-default {
  border-radius: 6px;
  border: solid;
}
.FormulaBuilderGeneralSection .rs-input-group.rs-input-group-inside .rs-input {
  border: solid;
  line-height: normal;
}

.rs-formula-name-picker-select-menu .rs-picker-select-menu-item > div {
  padding: 6px 12px;
  border-bottom: 1px solid #9d9d9d5c;
}
.rs-formula-name-picker-select-menu .rs-picker-select-menu-item {
  padding: 0;
}
.rs-formula-name-picker-select-menu {
  padding: 0;
}
.formula-drop-down {
  display: flex;
  gap: 5px;
  align-items: center;
}
.FormulaBuilderGeneralSection .row.mb-3 {
  align-items: center;
}
.formula-drop-down .btn.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border: solid;
}
.FormulaBuilderGeneralSection .rs-picker-toggle-value {
  display: block;
  overflow: visible;
}
.FormulaBuilderGeneralSection .rs-input-group {
  border: none;
}

.FormulaBuilderGeneralSection .rs-picker-toggle.rs-btn {
  /* border: 1px solid var(--rs-border-primary); */
  background: transparent !important;
  border: none;
}
.FormulaBuilderGeneralSection .rs-form-group {
  border-radius: 6px;
  overflow: hidden;
}
.FormulaBuilderGeneralSection .rs-picker-toggle-textbox {
  border: none;
}
