.no-internet-popup {
  /* position: fixed;
  top: 10px;
  left: 10px; */
  background-color: red;
  color: white;
  padding: 10px;
  z-index: 9999;
}
.InputSelectOptions {
  position: absolute;
  width: 27.28rem;
}
.InputSelectOptions li {
  text-align: center;
}
.onlineStatusBar {
  float: right !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
td {
  /* border-bottom: 1px !important;
  border-right: 1px !important; */
}
#ingredientTable td {
  /* width: 16% !important; */
  /* border-bottom: 1px !important;
  border-right: 1px !important; */
}
.cHBtDs {
  border-bottom: 1px !important;
  border-right: 1px !important;
  border: 1px !important;
}
.DragOverlay {
  width: 100% !important;
  border-bottom: 1px !important;
  border-right: 1px !important;
}
/* .table-bordered td {
  border-bottom: 1px !important;
  border-right: 1px !important;
  border: 1px !important;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

